export const linesConfig = {
  size: 3,
  defaultColor: '#b6b5b5',
  selectedColor: '#2496FF',
  removeIconColor: '#2496ff',
  defaultLabel: 'mock label',
  removeLineIconWidth: 18,
  removeLineIconHeight: 18,
};

export function generateRemoveIcon(xCoord: number, yCoord: number, isCampaign: boolean) {
  return `<image x="${xCoord}" y="${yCoord - 10}" width="${
    linesConfig.removeLineIconWidth
  }" height="${linesConfig.removeLineIconHeight}" href=${
    isCampaign
      ? '../../../../assets/icons/delete-link-in-campaign.svg'
      : '../../../../assets/icons/delete-icon.svg'
  } />`;
}
