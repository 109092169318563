import { Injectable } from '@angular/core';

import { cloneDeep } from 'lodash';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject } from 'rxjs';
import { Status, TemplateStatusDto } from '../../constants/promotion-form.data';
import { UserRolesDto } from '../../constants/user.data';
import { workSpaceContent } from '../../constants/work-space.data';
import { AuthTokens, Location, UserData } from '../../interfaces/auth/auth.interface';
import { Campaign } from '../../interfaces/campaign/campaign.interface';
import { ConditionLocation } from '../../interfaces/condition/condition.interface';
import { WorkSpaceContent } from '../../interfaces/working-space/work-space-data.interface';
import { OpenedEntity } from '../../interfaces/working-space/work-space-events.interface';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private cookies: CookieService) {}

  public userInfo = new BehaviorSubject(
    this.cookies.get('userInfo') ? JSON.parse(this.cookies.get('userInfo')) : null,
  );

  public setUserInfo(userInfo: UserData): void {
    this.cookies.set('userInfo', JSON.stringify(userInfo));
    this.userInfo.next(userInfo);
  }

  public redirectPath = new BehaviorSubject<OpenedEntity>(null);

  public setTokens(tokens: AuthTokens): void {
    this.cookies.set('_a', tokens.accessToken);
    this.cookies.set('_r', tokens.refreshToken);
  }

  public setUserRights(campaignId: number = null, promotionId: number = null): boolean {
    if (campaignId && !promotionId) {
      const campaign = cloneDeep(workSpaceContent[0]);

      return this.checkUserRights(campaignId, null, campaign);
    } else if (promotionId) {
      const promotion = workSpaceContent[0]?.promotions?.length
        ? workSpaceContent[0].promotions.find((promotion: any) => {
            return promotion.id === promotionId && !promotion?.name;
          })
        : workSpaceContent.find((el) => {
            return el.id === promotionId && !el?.name;
          });

      const adjustedPromotion = workSpaceContent[0]?.promotions?.length
        ? workSpaceContent[0].promotions
            .map((promotion: any) => {
              return promotion.promotion?.adjustedPromotions?.find((adjust: any) => {
                return adjust.id === promotionId;
              });
            })
            .filter(Boolean)[0]
        : workSpaceContent
            .map((el) => {
              return el.promotion?.adjustedPromotions?.find((adjust: any) => {
                return adjust.id === promotionId;
              }) as WorkSpaceContent | Campaign;
            })
            .filter(Boolean)[0];
      const promotionForCheck = promotion?.id && !adjustedPromotion ? promotion : adjustedPromotion;

      return this.checkUserRights(null, promotionId, promotionForCheck);
    } else {
      return false;
    }
  }

  public checkUserRights(
    campaignId: number = null,
    promotionId: number = null,
    item: WorkSpaceContent | Campaign,
  ): boolean {
    const userData = this.userInfo.getValue();
    try {
      if (userData.roles[0].name === UserRolesDto.Admin) {
        return true;
      }
      if (item?.status) {
        if (item.status !== TemplateStatusDto.Template) {
          if (item.status !== Status.Draft) {
            return false;
          }
        }
      } else if (
        item?.promotion.status !== Status.Draft &&
        item?.promotion.status !== TemplateStatusDto.Template
      ) {
        return false;
      }

      if (
        (userData.roles[0].name === UserRolesDto.StoreEmployee ||
          userData.roles[0].name === UserRolesDto.AdvancedUser) &&
        (item?.status === Status.Draft ||
          item?.status === Status.Review ||
          item.status === TemplateStatusDto.Template ||
          item?.status === Status.PreLaunch ||
          item?.promotion?.status === Status.Review ||
          item?.promotion?.status === Status.PreLaunch ||
          item?.promotion?.status === Status.Draft ||
          item?.promotion.status === TemplateStatusDto.Template)
      ) {
        const deepLocations = userData.locations?.map((el: Location) => {
          return el.name;
        });

        if (campaignId) {
          const campaignLocations = item.conditions.filter((el) => {
            return (el as ConditionLocation)?.locations;
          });

          const promotionsLocations = item.promotions.reduce(
            (acc: ConditionLocation[], curr: WorkSpaceContent) => {
              acc = acc.concat(
                curr?.conditions.filter(
                  (el) =>
                    (el as ConditionLocation)?.locations && !(el as ConditionLocation)?.isInherited,
                ) as ConditionLocation[],
              );
              return acc;
            },
            [],
          );

          const locationsNames = campaignLocations
            .concat(promotionsLocations)
            .reduce((acc: string[], curr) => {
              acc = acc.concat(
                (curr as ConditionLocation).locations.map((el) => el.fullLocationPath),
              );
              return acc;
            }, []);

          if (!locationsNames?.length) {
            return true;
          }

          return this.isLocationsEqual(deepLocations, locationsNames);
        }
        if ((item as WorkSpaceContent)?.campaignId) {
          const campaign = workSpaceContent.find((workSpaceItem) => {
            return workSpaceItem.id === (item as WorkSpaceContent)?.campaignId;
          });

          const campaignLocations = campaign?.conditions.filter((el) => {
            return (el as ConditionLocation)?.locations;
          });

          const promotionsLocations = item.conditions.filter(
            (el) => (el as ConditionLocation)?.locations,
          );

          const locationsNames = item.promotion.parentPromotionId
            ? promotionsLocations.reduce((acc: string[], curr) => {
                acc = acc.concat(
                  (curr as ConditionLocation).locations.map((el) => el.fullLocationPath),
                );
                return acc;
              }, [])
            : campaignLocations.concat(promotionsLocations).reduce((acc: string[], curr) => {
                acc = acc.concat(
                  (curr as ConditionLocation).locations.map((el) => el.fullLocationPath),
                );
                return acc;
              }, []);

          if (!locationsNames?.length) {
            return true;
          }

          return this.isLocationsEqual(deepLocations, locationsNames);
        }
        if (!campaignId && promotionId) {
          const promotion = cloneDeep(item);
          const locations = promotion.conditions.filter(
            (el) => (el as ConditionLocation)?.locations,
          );
          const locationsNames = (locations as ConditionLocation[]).reduce(
            (acc: string[], curr) => {
              const names = curr.locations.map((el) => el.fullLocationPath);
              acc = acc.concat(names);
              return acc;
            },
            [],
          );

          if (!locationsNames?.length) {
            return true;
          }

          return this.isLocationsEqual(deepLocations, locationsNames);
        }
      }

      return false;
    } catch {}
    return false;
  }

  public isUserLocationIncorrect(locations: string): boolean {
    const userData = this.userInfo.getValue();
    if (userData.roles[0].name === UserRolesDto.Admin) {
      return true;
    }

    const deepLocations = userData.locations?.map((el: Location) => {
      const locations = el.name.split('.');
      return locations[locations.length - 1];
    });

    const mappedLocations = locations.split('.');

    return deepLocations.some((el: string) => {
      return mappedLocations.some((loc) => loc === el);
    });
  }

  public getUserRole(): string {
    return this.userInfo.getValue().roles[0].name;
  }

  public getUserLocations(): string[] {
    const userData = this.userInfo.getValue();
    const deepLocations = userData.locations?.map((el: Location) => {
      const locations = el.name.split('.');
      return locations[locations.length - 1];
    });

    return deepLocations;
  }

  public getFullUserLocations(): string {
    const userData = JSON.parse(this.cookies.get('userInfo'));

    const locationNames = userData.locations.map((location: Location) => {
      return location.name;
    }) as string[];

    const name = locationNames.reduce((prefix, str) => {
      let i = 0;
      while (i < prefix.length && i < str.length && prefix[i] === str[i]) {
        i++;
      }
      return prefix.substring(0, Math.min(i, prefix.length));
    });

    return name.charAt(name.length - 1) === '.' ? name.slice(0, -1) : name;
  }

  public getRootUserLocation(): string {
    const userData = JSON.parse(this.cookies.get('userInfo'));

    return userData.locations[0].name.split('.')[0];
  }

  public isLocationsEqual(deepLocations: string[], locationsNames: string[]): boolean {
    let locations = cloneDeep(locationsNames);

    for (const location of deepLocations) {
      locations = locations.filter((el) => !el.includes(location));
    }

    if (!locations.length) {
      return true;
    }

    return false;
  }

  public saveOpenedEntities(): void {
    if (workSpaceContent?.length) {
      if (workSpaceContent[0]?.promotions) {
        this.cookies.set('campaignId', `${workSpaceContent[0].id}`);
        this.cookies.set('promotionsIds', JSON.stringify([]));
      } else {
        this.cookies.set('campaignId', '');
        this.cookies.set('promotionsIds', JSON.stringify(workSpaceContent.map((el) => el.id)));
      }
    }
  }

  public deletePromotionFromCookies(id: number): void {
    const promotionIds = this.cookies.get('promotionsIds');
    if (promotionIds) {
      this.cookies.set(
        'promotionsIds',
        JSON.stringify(JSON.parse(promotionIds).filter((el: number) => el !== id)),
      );
    }
  }

  public deleteCampaignFromCookies(id: number): void {
    const campaignId = this.cookies.get('campaignId');

    if (campaignId && JSON.parse(campaignId) === id) {
      this.cookies.set('campaignId', '');
    }
  }

  public clearPromotions(): void {
    this.cookies.set('promotionsIds', JSON.stringify([]));
  }

  public clearCampaign(): void {
    this.cookies.set('campaignId', '');
  }

  public setCampaignIdInCookies(id: number): void {
    this.cookies.set('campaignId', `${id}`);
  }

  public clearOpenedEntities(): void {
    this.clearCampaign();
    this.clearPromotions();
  }

  public getSavedCampaignId(): number {
    const campaignId = this.cookies.get('campaignId');
    return campaignId ? +JSON.parse(campaignId) : null;
  }

  public getSavedPromotionsIds(): number[] {
    const promotionIds = this.cookies.get('promotionsIds');
    return promotionIds ? JSON.parse(promotionIds) : [];
  }
}
