import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FooterComponent } from './footer.component';

import { NzPopoverModule } from 'ng-zorro-antd/popover';


@NgModule({
  declarations: [FooterComponent],
  imports: [
    CommonModule,
    NzPopoverModule,
  ],
  exports: [FooterComponent],
})
export class FooterModule { }
