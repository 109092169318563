export const productsDropListData: string[] = ['buildingBLockDropListProduct'];

export const conditionsDropListData: string[] = ['buildingBLockDropListCondition'];

export const actionsDropListData: string[] = ['buildingBLockDropListAction'];

export const workSpaceDropListData: string[] = [
  'workSpaceDropListPromotion-1',
  'workSpaceDropListPromotion-2',
  'workSpaceDropListPromotion-3',
  'workSpaceDropListPromotion-4',
  'loyaltySchemeDndId',
];

export const testAreaBasketDropListData: string[] = [];

export const testAreaPromotionsDropListData: string[] = [];

export const nestedDropListGroupIdBase = 'nested-drop-list-group';

export const nestedDropListIdBase = 'nested-drop-list';
