import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getClass',
})
export class GetClassPipe implements PipeTransform {
  transform(value: string, showSelectLinkButtonType: any, type: any): unknown {
    return showSelectLinkButtonType === 'products' && type === 'conditions'
      ? `container-${type}-${showSelectLinkButtonType}`
      : `container-${type}`;
  }
}
