import { WorkSpaceContent } from '../interfaces/working-space/work-space-data.interface';
import { Campaign } from '../interfaces/campaign/campaign.interface';
import { WorkSpaceItemActionMenu } from '../interfaces/working-space/work-space-item-action-menu.interface';

export const workSpaceHeaders = [
  {
    title: 'catalogue-panel.work_space.headers.product',
    icon: 'shopping-cart',
  },
  {
    title: 'catalogue-panel.work_space.headers.condition',
    icon: 'filter',
  },
  {
    title: 'catalogue-panel.work_space.headers.action/target',
    icon: 'exclamation',
  },
];

export const loyaltyBoardHeaders = [
  { title: 'tree-header.general.title' },
  {
    title: 'catalogue-panel.work_space.headers.condition',
    icon: 'filter',
  },
  { title: 'tree-header.benefit_scheme.title', icon: 'exclamation' },
];

export const loyaltyGeneralItemsMenu = [
  { title: 'catalogue-panel.loyalty.menu_items.company', icon: '' },
  { title: 'catalogue-panel.loyalty.menu_items.account', icon: '' },
  { title: 'catalogue-panel.loyalty.menu_items.membership_properties', icon: '' },
];

export const TierConditionsItemsMenu = [
  { title: 'catalogue-panel.loyalty.menu_items.reach_condition', icon: '' },
  { title: 'catalogue-panel.loyalty.menu_items.lose_condition', icon: '' },
];

export const loyaltyprogramBenefitsItemsMenu = [
  { title: 'catalogue-panel.loyalty.menu_items.collect_points', icon: '' },
  { title: 'catalogue-panel.loyalty.menu_items.redeem_points', icon: '' },
  { title: 'catalogue-panel.loyalty.menu_items.voucher', icon: '' },
  { title: 'catalogue-panel.loyalty.menu_items.coupon', icon: '' },
  { title: 'catalogue-panel.loyalty.menu_items.early_access', icon: '' },
  { title: 'catalogue-panel.loyalty.menu_items.free_delivery', icon: '' },
];

export const LoyaltyProgammBuildingBlockActionMenuData: WorkSpaceItemActionMenu[] = [
  { title: 'catalogue-panel.work_space.building_block_action_menu.titles.edit', icon: 'edit' },
];

export const workSpaceActionMenuData: WorkSpaceItemActionMenu[] = [
  { title: 'catalogue-panel.work_space.item_action_menu.titles.edit', icon: 'edit' },
  { title: 'catalogue-panel.work_space.item_action_menu.titles.delete', icon: 'delete' },
  {
    title: 'catalogue-panel.work_space.item_action_menu.titles.save_to_catalog',
    icon: 'cloud-upload',
  },
  { title: 'catalogue-panel.work_space.item_action_menu.titles.export_json', icon: 'export' },
];

export const loyaltySpaceActionMenuData: WorkSpaceItemActionMenu[] = [
  { title: 'catalogue-panel.work_space.item_action_menu.titles.edit', icon: 'edit' },
];

export const workSpaceBuildingBlockActionMenuData: WorkSpaceItemActionMenu[] = [
  { title: 'catalogue-panel.work_space.building_block_action_menu.titles.edit', icon: 'edit' },
  { title: 'catalogue-panel.work_space.building_block_action_menu.titles.duplicate', icon: 'copy' },
  { title: 'catalogue-panel.work_space.building_block_action_menu.titles.group', icon: 'group' },
  {
    title: 'catalogue-panel.work_space.building_block_action_menu.titles.link',
    icon: 'link',
  },
  {
    title: 'catalogue-panel.work_space.building_block_action_menu.titles.save_to_catalog',
    icon: 'cloud-upload',
  },
  {
    title: 'catalogue-panel.work_space.building_block_action_menu.titles.delete',
    icon: 'delete',
  },
];

export const workSpaceContent: (WorkSpaceContent | Campaign)[] = [];

export const productMenuItems = [
  { title: 'catalogue-panel.work_space.menu_titles.article_filter', icon: 'shopping-cart' },
  { title: 'catalogue-panel.work_space.menu_titles.article_list', icon: 'shopping-cart' },
  { title: 'catalogue-panel.work_space.menu_titles.article_set', icon: 'shopping-cart' },
  { title: 'catalogue-panel.work_space.menu_titles.dynamic_set', icon: 'shopping-cart' },
  {
    title: 'catalogue-panel.work_space.menu_titles.direct_expert_mode',
    icon: 'code',
    isExpertMode: true,
  },
];

export const conditionMenuItems = [
  { title: 'catalogue-panel.work_space.menu_titles.item_selection' },
  { title: 'catalogue-panel.work_space.menu_titles.customer' },
  { title: 'catalogue-panel.work_space.menu_titles.time' },
  { title: 'catalogue-panel.work_space.menu_titles.location' },
  { title: 'catalogue-panel.work_space.menu_titles.coupon' },
  { title: 'catalogue-panel.work_space.menu_titles.direct_expert_mode', isExpertMode: true },
];

export const actionMenuItems = [
  { title: 'catalogue-panel.work_space.menu_titles.purchase_discount' },
  { title: 'catalogue-panel.work_space.menu_titles.scaled_discount' },
  { title: 'catalogue-panel.work_space.menu_titles.target_list' },
  { title: 'catalogue-panel.work_space.menu_titles.target_filter' },
  { title: 'catalogue-panel.work_space.menu_titles.giveaway_product' },
  { title: 'catalogue-panel.work_space.menu_titles.action_coupon' },
  { title: 'catalogue-panel.work_space.menu_titles.get_items_for_free' },
  { title: 'catalogue-panel.work_space.menu_titles.direct_expert_mode', isExpertMode: true },
];

export enum WorkspaceSections {
  Promotions = 'promotions',
  Conditions = 'conditions',
  Actions = 'actions',
  Products = 'products',
}

export enum ActionLists {
  TargetList = 'targetList',
  GiveawayProduct = 'giveawayProduct',
  TargetFilter = 'targetFilter',
  DynamicSet = 'dynamicSet',
}

export enum MenuTitles {
  ProductMenuTitle = 'catalogue-panel.work_space.menu_titles.product_selection',
  ConditionMenuTitle = 'catalogue-panel.work_space.menu_titles.item_selection',
  TimeMenuTitle = 'catalogue-panel.work_space.menu_titles.time',
  ActionMenuTitle = 'catalogue-panel.work_space.menu_titles.purchase_discount',
  LocationMenuTitle = 'catalogue-panel.work_space.menu_titles.location',
  ExpertModeTitle = 'catalogue-panel.work_space.menu_titles.direct_expert_mode',
  CustomerTitle = 'catalogue-panel.work_space.menu_titles.customer',
  ActionCouponTitle = 'catalogue-panel.work_space.menu_titles.action_coupon',
  TargetFilter = 'catalogue-panel.work_space.menu_titles.target_list',
  ConditionCouponTitle = 'catalogue-panel.work_space.menu_titles.coupon',
  TargetListTitle = 'catalogue-panel.work_space.menu_titles.target_list',
  ScaledDiscountTitle = 'catalogue-panel.work_space.menu_titles.scaled_discount',
}

export enum ConditionTypes {
  ItemSelection = 'itemSelection',
  Time = 'time',
  Location = 'location',
  Customer = 'customer',
  Coupon = 'coupon',
}

export enum ExpertModeType {
  ExpertModeItem = 'expert mode item',
}

export enum ActionTypes {
  Purchase = 'purchase',
  ActionCoupon = 'actionCoupon',
  ScaledDiscount = 'scaledDiscount',
  TargetList = 'targetList',
  TargetFilter = 'targetFilter',
  GiveawayProduct = 'giveawayProduct',
  GetForFree = 'getForFree',
  ExpertModeItem = 'expert mode item',
}

export const clearButtonTooltip = 'catalogue-panel.work_space.clear_button_tooltip';

export const clearButtonTitle = 'catalogue-panel.work_space.info:';

export const catalogueCampaignTitle = 'catalogue-panel.campaigns.title:';

export const catalogueStandAlongTitle = 'catalogue-panel.work_space.stand_alone_promotions';
