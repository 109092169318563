import { NgModule } from '@angular/core';
import { ChangeDateFormatPipe } from './change-date-format.pipe';
import { GetClassPipe } from './get-class.pipe';
import { GetErrorPipe } from './get-error.pipe';
import { IsHighlightPipe } from './is-highlight.pipe';
import { IsItemInArrayPipe } from './is-item-in-array.pipe';
import { LimiterPipe } from './limiter.pipe';

@NgModule({
  declarations: [
    GetClassPipe,
    ChangeDateFormatPipe,
    GetErrorPipe,
    IsHighlightPipe,
    LimiterPipe,
    IsItemInArrayPipe,
  ],
  exports: [
    GetClassPipe,
    ChangeDateFormatPipe,
    GetErrorPipe,
    IsHighlightPipe,
    LimiterPipe,
    IsItemInArrayPipe,
  ],
})
export class PipesModule {}
