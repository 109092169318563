import { ApplicationRef, Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';

import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject } from 'rxjs';
import { LineService } from '../line/line.service';
import { ModalService } from '../modal/modal.service';

import { workSpaceContent } from '../../constants/work-space.data';

@Injectable({
  providedIn: 'root',
})
export class AutoLogoutService {
  public minutesBeforeLogout = new BehaviorSubject(20);

  public workspaceQueryParams$ = new BehaviorSubject('');

  public interval = 1500;

  public cookiesKey = 'lastAction';

  constructor(
    private router: Router,
    private cookies: CookieService,
    private lineService: LineService,
    private zone: NgZone,
    private modalService: ModalService,
    private app: ApplicationRef,
  ) {
    this.check();
    this.initInterval();
    this.cookies.set(this.cookiesKey, Date.now().toString());
  }

  public getLastAction(): number {
    return parseInt(this.cookies.get(this.cookiesKey), 10);
  }

  public setLastAction(lastAction: number): void {
    this.cookies.set(this.cookiesKey, lastAction.toString());
  }

  public reset(): void {
    this.setLastAction(Date.now());
  }

  public initInterval(): void {
    this.zone.runOutsideAngular(() => {
      setInterval(() => {
        this.check();
      }, this.interval);
    });
  }

  public check(): void {
    const now = Date.now();

    const timeLeft = this.getLastAction() + this.minutesBeforeLogout.value * 60 * 1000;
    const diff = timeLeft - now;
    const isTimeout = diff < 0;

    if (isTimeout) {
      this.zone.run(() => {
        workSpaceContent.splice(0, workSpaceContent?.length);
        this.modalService.closeModal();
        this.lineService.deleteAllLines();
        this.cookies.deleteAll();
        this.router.navigate(['login']);
        this.app.tick();
      });
    }
  }
}
