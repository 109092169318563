import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';

import { CookieService } from 'ngx-cookie-service';
import { OpenedEntity } from '../interfaces/working-space/work-space-events.interface';
import { AuthService } from '../services/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class CheckUserInfoGuard  {
  constructor(
    private cookies: CookieService,
    private router: Router,
    private authService: AuthService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const userInfo = this.cookies.get('userInfo');

    if (userInfo) {
      return true;
    }

    const startingPath = route.url[0].path;
    const queryParams = {
      type: route.queryParamMap.get('type'),
      ids: route.queryParamMap.get('ids'),
    };

    const redirectPathWithQueryParams = {
      type: route.queryParamMap.get('type'),
      ids: JSON.parse(route.queryParamMap.get('ids')),
    };

    this.authService.redirectPath.next(redirectPathWithQueryParams as OpenedEntity);
    this.cookies.set('startingPath', startingPath);
    this.cookies.set('queryParams', JSON.stringify(queryParams));
    this.router.navigate(['login']);
    return false;
  }
}
