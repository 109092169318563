<header class="header-wrapper" [class.empty-header]="!isNavigationVisible">
  <h1 class="header-wrapper__title">
    <span class="header-wrapper__title-main">Gebit</span>
    <span>Campaign Manager</span>
  </h1>
  <div class="header-wrapper_action">
    <app-internationalize></app-internationalize>
    <nav *ngIf="isNavigationVisible" class="header-wrapper__menu">
      <ul class="header-wrapper__menu-list">
        <li class="header-wrapper__menu-list__item">
          <a
            [class.active]="currentRoute === '/dashboard'"
            class="link"
            data-cy="dashboard-tab"
            routerLink="dashboard"
            >{{ 'header.dashboard' | translate }}</a
          >
        </li>
        <li class="header-wrapper__menu-list__item">
          <a
            [class.active]="currentRoute === '/editor'"
            class="link"
            data-cy="editor-tab"
            (click)="navigateEditor()"
            >{{ 'header.editor' | translate }}</a
          >
        </li>
        <li class="header-wrapper__menu-list__item">
          <a
            [class.active]="currentRoute === '/loyalty'"
            class="link"
            data-cy="loyalty-tab"
            routerLink="loyalty"
            >{{ 'header.loyalty_programme' | translate }}</a
          >
        </li>
        <li class="header-wrapper__menu-list__item">
          <a
            [class.active]="currentRoute === '/simulator'"
            class="link"
            data-cy="simulator-tab"
            routerLink="simulator"
            >{{ 'header.simulator' | translate }}</a
          >
        </li>
      </ul>
      <div class="header-wrapper__menu-user">
        <div
          class="header-wrapper__menu-user__icon"
          nz-popover
          nzPopoverPlacement="leftTop"
          [nzPopoverContent]="userDetails"
          data-cy="user-profile-icon"
        >
          <ng-container *ngIf="userInfo">
            <span
              *ngIf="userInfo.roleName === userRoles.Admin"
              class="header-wrapper__menu-user__icons"
            >
              <em class="star" nz-icon nzType="star" nzTheme="fill"></em>
              <em class="star" nz-icon nzType="star" nzTheme="fill"></em>
            </span>
          </ng-container>

          <ng-container *ngIf="userInfo">
            <span
              *ngIf="userInfo.roleName === userRoles.CentralEmployee"
              class="header-wrapper__menu-user__icons single"
            >
              <em class="star" nz-icon nzType="star" nzTheme="fill"></em>
            </span>
          </ng-container>

          <p class="header-wrapper__menu-user__name">
            {{ (userInfo?.firstName[0] || '') + (userInfo?.lastName[0] || '') }}
          </p>
        </div>
      </div>
    </nav>
  </div>
</header>

<ng-template #userDetails>
  <div *ngIf="userInfo?.username">
    <span class="label">{{ 'user_info.username' | translate }}:</span>
    <span>{{ userInfo.username }}</span>
  </div>
  <div *ngIf="userInfo?.email">
    <span class="label">{{ 'user_info.email' | translate }}:</span>
    <span>{{ userInfo.email }}</span>
  </div>
  <div *ngIf="userInfo?.firstName">
    <span class="label">{{ 'user_info.first_name' | translate }}:</span>
    <span class="user-value">{{ userInfo.firstName }}</span>
  </div>
  <div *ngIf="userInfo?.lastName">
    <span class="label">{{ 'user_info.last_name' | translate }}:</span>
    <span class="user-value">{{ userInfo.lastName }}</span>
  </div>
  <div *ngIf="userInfo?.roleName">
    <span class="label">{{ 'user_info.role' | translate }}:</span>
    <span class="user-value">{{ userInfo.roleName }}</span>
  </div>
  <div *ngIf="userInfo?.locations?.length && userInfo?.roleName === userRoles.StoreEmployee">
    <p class="locations">
      <span class="label">{{ 'user_info.store' | translate }}:</span>
      <span class="locations__item" *ngFor="let location of userInfo.locations">{{
        location.name
      }}</span>
    </p>
  </div>
  <button nz-button nzType="primary" class="button" nzSize="small" (click)="logOut()">
    {{ 'common.log_out' | translate }}
  </button>
</ng-template>
