import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LinkDtoCombined } from '../../../interfaces/link/link.interface';
import { EnvConfigurationService } from '../../environment/env-configuration-service.service';

@Injectable({
  providedIn: 'root',
})
export class LinkApiService {
  private url = this.envConfigurationService.configuration?.BASE_URL;

  constructor(
    private httpClient: HttpClient,
    private envConfigurationService: EnvConfigurationService,
  ) {}

  public saveLink(item: LinkDtoCombined): Observable<LinkDtoCombined> {
    return this.httpClient.post<LinkDtoCombined>(`${this.url}/link`, item);
  }

  public updateLink(item: LinkDtoCombined): Observable<LinkDtoCombined> {
    return this.httpClient.put<LinkDtoCombined>(`${this.url}/link`, item);
  }

  public getLinkById(id: number): Observable<LinkDtoCombined> {
    return this.httpClient.get<LinkDtoCombined>(`${this.url}/link/${id}`);
  }

  public deleteLink(id: number): Observable<null> {
    return this.httpClient.delete<null>(`${this.url}/link/${id}`);
  }

  public getPromotionLinks(id: number): Observable<LinkDtoCombined[]> {
    return this.httpClient.get<LinkDtoCombined[]>(`${this.url}/link/promotion/${id}`);
  }

  public getCampaignLinks(id: number): Observable<LinkDtoCombined[]> {
    return this.httpClient.get<LinkDtoCombined[]>(`${this.url}/link/campaign/${id}`);
  }
}
