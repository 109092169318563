<div class="internationalize">
  <nz-select
    class="options"
    [(ngModel)]="languagesControl"
    (ngModelChange)="setLanguage($event)"
    [nzCustomTemplate]="custom"
    nzCustomContent
    [nzShowArrow]="true"
    nzSuffixIcon="caret-down"
  >
    <nz-option
      nzCustomContent
      *ngFor="let item of languages"
      [nzLabel]="item.label"
      [nzValue]="item.value"
    >
      <div class="option">
        <img class="language-icon" [src]="'../../../../assets/icons/' + item.icon + '.svg'" />
        <span>{{ item.label }}</span>
      </div>
    </nz-option>
  </nz-select>
  <ng-template class="option-selected" #custom let-selected>
    <img class="language-icon" [src]="'../../../../assets/icons/' + selected.nzValue + '.svg'" />
    <span>{{ selected.nzLabel }}</span>
  </ng-template>
</div>
