export interface PopConfirmContent {
  title: string;
  body: string;
  cancel: string;
  confirm: string;
  additionalInfo?: string;
  type: PopConfirmTypesEnum;
}

export enum PopConfirmTypesEnum {
  Replace = 'replace',
  InstanceOrTemplate = 'instance or template',
  InstanceOrIncludeToCampaign = 'instance or include to campaign',
}
