import { CatalogueFilterStatuses } from '../enums/filter-statuses-types.enum';
import { TreeNode } from '../interfaces/tree-node/tree-node.interface';

export enum CatalogPages {
  Templates = 'templates',
  RunningInstances = 'runningInstances',
}

export const LoyaltyMockData: TreeNode[] = [
  {
    title: '',
    children: [
      {
        title: '',
        nodeData: {
          id: 'mock',
          title: '',
          include: false,
          logicalExpression: [],
          products: [
            {
              id: 1,
              productGroupName: 'Backing',
              productName: 'Sugar',
              productGroup: 321,
              basePrice: 12,
              listPrice: 900,
              image: 'https://i.ytimg.com/vi/Q0TSsRtctEQ/maxresdefault.jpg',
              selected: true,
            },
          ],
        },
      },
    ],
    icon: '',
  },
];

export const LoyaltyConditionsData: TreeNode[] = [
  {
    title: 'tree-header.conditions.title',
    icon: '',
    nodeData: { id: 'mock' },
    children: [
      {
        title: 'catalogue-panel.work_space.menu_titles.time',
        nodeData: { id: 'mock' },
        children: [
          {
            title: '',
            nodeData: {
              id: 'mock',
              title: '',
              products: null,
              include: false,
              logicalExpression: [],
            },
          },
        ],
        icon: 'clock-circle',
      },
      {
        title: 'catalogue-panel.work_space.menu_titles.location',
        nodeData: { id: 'mock' },
        children: [
          {
            title: '',
            nodeData: {
              id: 'mock',
              title: '',
              products: null,
              include: false,
              logicalExpression: [],
            },
          },
        ],
        icon: 'environment',
      },
    ],
  },
];

export const LoyaltyBenefitsData: TreeNode[] = [
  {
    title: 'tree-header.benefits.title',
    icon: '',
    nodeData: { id: 'mock' },
    children: [
      {
        title: '',
        nodeData: {
          id: 'mock',
          title: '',
          products: null,
          include: false,
          logicalExpression: [],
        },
      },
    ],
  },
];

export const BuildingBlockProductsData: TreeNode[] = [
  {
    title: 'tree-header.products.title',
    children: [
      {
        title: 'catalogue-panel.work_space.menu_titles.article_filter',
        icon: 'shopping-cart',
        nodeData: { id: 'mock' },
        children: [
          {
            title: '',
            nodeData: {
              id: 'mock',
              title: '',
              products: null,
              include: false,
              logicalExpression: [],
            },
          },
        ],
      },
      {
        title: 'catalogue-panel.work_space.menu_titles.article_list',
        icon: 'shopping-cart',
        nodeData: { id: 'mock' },
        children: [
          {
            title: '',
            nodeData: {
              id: 'mock',
              title: '',
              products: null,
              include: false,
              logicalExpression: [],
            },
          },
        ],
      },
      {
        title: 'catalogue-panel.work_space.menu_titles.article_set',
        icon: 'shopping-cart',
        nodeData: { id: 'mock' },
        children: [
          {
            title: '',
            nodeData: {
              id: 'mock',
              title: '',
              products: null,
              include: false,
              logicalExpression: [],
            },
          },
        ],
      },
      {
        title: 'catalogue-panel.work_space.menu_titles.dynamic_set',
        icon: 'shopping-cart',
        nodeData: { id: 'mock' },
        children: [
          {
            title: '',
            nodeData: {
              id: 'mock',
              title: '',
              products: null,
              include: false,
              logicalExpression: [],
            },
          },
        ],
      },
      {
        title: 'catalogue-panel.work_space.menu_titles.direct_expert_mode',
        nodeData: { id: 'mock' },
        children: [
          {
            title: '',
            nodeData: {
              id: 'mock',
              title: '',
              products: null,
              include: false,
              logicalExpression: [],
            },
          },
        ],
        icon: 'code',
      },
    ],
    icon: '',
  },
];

export const BuildingBlockConditionsData: TreeNode[] = [
  {
    title: 'tree-header.conditions.title',
    icon: '',
    nodeData: { id: 'mock' },
    children: [
      {
        title: 'catalogue-panel.work_space.menu_titles.item_selection_value',
        nodeData: { id: 'mock' },
        children: [
          {
            title: '',
            nodeData: {
              id: 'mock',
              title: '',
              products: null,
              include: false,
              logicalExpression: [],
            },
          },
        ],
        icon: 'tag',
      },
      {
        title: 'catalogue-panel.work_space.menu_titles.customer',
        icon: 'user',
        nodeData: { id: 'mock' },
        children: [
          {
            title: '',
            nodeData: {
              id: 'mock',
              title: '',
              products: null,
              include: false,
              logicalExpression: [],
            },
          },
        ],
      },
      {
        title: 'catalogue-panel.work_space.menu_titles.time',
        nodeData: { id: 'mock' },
        children: [
          {
            title: '',
            nodeData: {
              id: 'mock',
              title: '',
              products: null,
              include: false,
              logicalExpression: [],
            },
          },
        ],
        icon: 'clock-circle',
      },
      {
        title: 'catalogue-panel.work_space.menu_titles.location',
        nodeData: { id: 'mock' },
        children: [
          {
            title: '',
            nodeData: {
              id: 'mock',
              title: '',
              products: null,
              include: false,
              logicalExpression: [],
            },
          },
        ],
        icon: 'environment',
      },

      {
        title: 'catalogue-panel.work_space.menu_titles.coupon',
        icon: 'credit-card',
        nodeData: { id: 'mock' },
        children: [
          {
            title: '',
            nodeData: {
              id: 'mock',
              title: '',
              products: null,
              include: false,
              logicalExpression: [],
            },
          },
        ],
      },
      {
        title: 'catalogue-panel.work_space.menu_titles.direct_expert_mode',
        nodeData: { id: 'mock' },
        children: [
          {
            title: '',
            nodeData: {
              id: 'mock',
              title: '',
              products: null,
              include: false,
              logicalExpression: [],
            },
          },
        ],
        icon: 'code',
      },
    ],
  },
];

export const BuildingBlockActionsData: TreeNode[] = [
  {
    title: 'tree-header.actions.title',
    icon: '',
    nodeData: { id: 'mock' },
    children: [
      {
        title: 'catalogue-panel.work_space.menu_titles.purchase_discount',
        icon: 'percentage',
        nodeData: { id: 'mock' },
        children: [
          {
            title: '',
            nodeData: {
              id: 'mock',
              title: '',
              products: null,
              include: false,
              logicalExpression: [],
            },
          },
        ],
      },
      {
        title: 'catalogue-panel.work_space.menu_titles.scaled_discount',
        icon: 'percentage',
        nodeData: { id: 'mock' },
        children: [
          {
            title: '',
            nodeData: {
              id: 'mock',
              title: '',
              products: null,
              include: false,
              logicalExpression: [],
            },
          },
        ],
      },
      {
        title: 'catalogue-panel.work_space.menu_titles.target_list',
        icon: 'shopping-cart',
        nodeData: { id: 'mock' },
        children: [
          {
            title: '',
            nodeData: {
              id: 'mock',
              title: '',
              products: null,
              include: false,
              logicalExpression: [],
            },
          },
        ],
      },
      {
        title: 'catalogue-panel.work_space.menu_titles.target_filter',
        icon: 'shopping-cart',
        nodeData: { id: 'mock' },
        children: [
          {
            title: '',
            nodeData: {
              id: 'mock',
              title: '',
              products: null,
              include: false,
              logicalExpression: [],
            },
          },
        ],
      },
      {
        title: 'catalogue-panel.work_space.menu_titles.giveaway_product',
        icon: 'gift',
        nodeData: { id: 'mock' },
        children: [
          {
            title: '',
            nodeData: {
              id: 'mock',
              title: '',
              products: null,
              include: false,
              logicalExpression: [],
            },
          },
        ],
      },
      {
        title: 'catalogue-panel.work_space.menu_titles.action_coupon',
        icon: 'credit-card',
        nodeData: { id: 'mock' },
        children: [
          {
            title: '',
            nodeData: {
              id: 'mock',
              title: '',
              products: null,
              include: false,
              logicalExpression: [],
            },
          },
        ],
      },
      {
        title: 'catalogue-panel.work_space.menu_titles.direct_expert_mode',
        nodeData: { id: 'mock' },
        children: [
          {
            title: '',
            nodeData: {
              id: 'mock',
              title: '',
              products: null,
              include: false,
              logicalExpression: [],
            },
          },
        ],
        icon: 'code',
      },
    ],
  },
];

export const promotionsTabData: TreeNode[] = [
  {
    title: 'catalogue-panel.work_space.menu_titles.templates',
    children: [
      {
        title: 'catalogue-panel.campaigns.title',
        children: [
          {
            title: 'promotion 1',
            nodeData: {
              id: 'mock',
              promotion: {
                id: 'mock',
                name: 'cucumber',
                description: 'string',
                coexistence: 'string',
                numberOfInstances: 12,
                executionLevel: 12,
                channel: [],
                channelDescription: 'string',
                status: 'string',
                createdAt: new Date(),
                user: 'string',
                linkedItemsData: [],
              },
              actions: [],
              conditions: [],
              products: [],
            },
            children: [],
          },
        ],
      },
      {
        title: 'catalogue-panel.work_space.stand_alone_promotions',
        children: [
          {
            title: 'promotion 1',
            nodeData: {
              id: 'mock',
              promotion: {
                id: 'mock',
                name: 'cucumber',
                description: 'string',
                coexistence: 'string',
                numberOfInstances: 12,
                executionLevel: 12,
                channel: [],
                channelDescription: 'string',
                status: 'string',
                createdAt: new Date(),
                user: 'string',
                linkedItemsData: [],
              },
              actions: [],
              conditions: [],
              products: [],
            },
          },
        ],
      },
    ],
  },
];

export const loyaltyTemplatesTabData: TreeNode[] = [
  {
    title: 'catalogue-panel.work_space.menu_titles.templates',
    children: [
      {
        title: 'catalogue-panel.loyalty.catalog.schemes.title',
        children: [
          {
            title: 'promotion 1',
            nodeData: {
              id: 'mock',
              promotion: {
                id: 'mock',
                name: 'cucumber',
                description: 'string',
                coexistence: 'string',
                numberOfInstances: 12,
                executionLevel: 12,
                channel: [],
                channelDescription: 'string',
                status: 'string',
                createdAt: new Date(),
                user: 'string',
                linkedItemsData: [],
              },
              actions: [],
              conditions: [],
              products: [],
            },
            children: [],
          },
        ],
      },
    ],
  },
];

export const loyaltyRunningInstances: TreeNode[] = [
  {
    title: 'catalogue-panel.work_space.menu_titles.instances',
    children: [
      {
        title: 'catalogue-panel.loyalty.catalog.schemes.title',
        nodeData: { id: 'campaigns' },
        children: [
          {
            title: 'scheme 1',
            nodeData: {
              id: 'mock',
              promotion: {
                id: 'mock',
                name: 'cucumber',
                description: 'string',
                coexistence: 'string',
                numberOfInstances: 12,
                executionLevel: 12,
                channel: [],
                channelDescription: 'string',
                status: 'string',
                createdAt: new Date(),
                user: 'string',
                linkedItemsData: [],
              },
              actions: [],
              conditions: [],
              products: [],
            },
            children: [],
          },
        ],
      },
    ],
  },
];

export const runningInstances: TreeNode[] = [
  {
    title: 'catalogue-panel.work_space.menu_titles.instances',
    children: [
      {
        title: 'catalogue-panel.campaigns.title',
        nodeData: { id: 'campaigns' },
        children: [
          {
            title: 'promotion 1',
            nodeData: {
              id: 'mock',
              promotion: {
                id: 'mock',
                name: 'cucumber',
                description: 'string',
                coexistence: 'string',
                numberOfInstances: 12,
                executionLevel: 12,
                channel: [],
                channelDescription: 'string',
                status: 'string',
                createdAt: new Date(),
                user: 'string',
                linkedItemsData: [],
              },
              actions: [],
              conditions: [],
              products: [],
            },
            children: [],
          },
        ],
      },
      {
        title: 'catalogue-panel.work_space.stand_alone_promotions',
        nodeData: { id: 'promotions' },
        children: [
          {
            title: 'promotion 1',
            nodeData: {
              id: 'mock',
              promotion: {
                id: 'mock',
                name: 'cucumber',
                description: 'string',
                coexistence: 'string',
                numberOfInstances: 12,
                executionLevel: 12,
                channel: [],
                channelDescription: 'string',
                status: 'string',
                createdAt: new Date(),
                user: 'string',
                linkedItemsData: [],
              },
              actions: [],
              conditions: [],
              products: [],
            },
          },
        ],
      },
    ],
  },
];

export const mockCampaign: TreeNode = {
  title: 'promotion 1',
  nodeData: {
    id: 'mock',
    promotion: {
      id: 'mock',
      name: 'cucumber',
      description: 'string',
      coexistence: 'string',
      numberOfInstances: 12,
      executionLevel: 12,
      channel: [],
      channelDescription: 'string',
      status: 'string',
      createdAt: new Date(),
      user: 'string',
      linkedItemsData: [],
    },
    actions: [],
    conditions: [],
    products: [],
  },
  children: [],
};

export const FilterFormData = [
  {
    label: 'catalogue-panel.work_space.modal.campaign_statuses.in_progress',
    value: CatalogueFilterStatuses.InProgress,
    checked: false,
  },
  {
    label: 'catalogue-panel.work_space.modal.campaign_statuses.active',
    value: CatalogueFilterStatuses.Active,
    checked: false,
  },
  {
    label: 'catalogue-panel.work_space.modal.campaign_statuses.closed',
    value: CatalogueFilterStatuses.Closed,
    checked: false,
  },
];

export const GroupTypeData = {
  products: 'PRODUCT_GROUP',
  conditions: 'CONDITION_GROUP',
  actions: 'ACTION_GROUP',
};
