import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import {
  BuildingBlockActionsData,
  BuildingBlockConditionsData,
  BuildingBlockProductsData,
} from '../../constants/building-block.data';
import { WorkspaceSections } from '../../constants/work-space.data';
import { CatalogData } from '../../interfaces/working-space/work-space-events.interface';
import { CatalogActionApiService } from '../api/catalog/catalog-action-api.service';
import { CatalogConditionApiService } from '../api/catalog/catalog-condition-api.service';
import { CatalogProductApiService } from '../api/catalog/catalog-product-api.service';
import { SaveTemplateService } from '../saveTemplate/save-template.service';
import { SpinService } from '../spin/spin.service';

@Injectable({
  providedIn: 'root',
})
export class CatalogueService {
  public isClearFilters$ = new Subject<boolean>();

  public catalogueItemForUpdate$ = new BehaviorSubject<any>(null);

  public cataloguePromotionOrCampaignForUpdate$ = new BehaviorSubject<any>(null);

  public cataloguePromotionsFilter$ = new BehaviorSubject<{
    filters: string[];
    searchValue: string;
  }>(null);

  public catalogueCampaignsFilter$ = new BehaviorSubject<{
    filters: string[];
    searchValue: string;
  }>(null);

  public isEditFromCatalogue = false;

  constructor(
    private spinService: SpinService,
    private saveTemplateService: SaveTemplateService,
    private catalogProductApiService: CatalogProductApiService,
    private catalogActionApiService: CatalogActionApiService,
    private catalogConditionApiService: CatalogConditionApiService,
  ) {}

  public onDeleteItem(data: CatalogData): void {
    switch (data.type) {
      case WorkspaceSections.Products: {
        const indexOfItem = BuildingBlockProductsData[0].children.findIndex(
          (el) => el.nodeData.id === data.id,
        );

        BuildingBlockProductsData[0].children.splice(indexOfItem, 1);
        this.saveTemplateService.isSaved.next(true);
        this.catalogProductApiService
          .deleteProductFromCatalog(data.id)
          .subscribe(() => this.spinService.isItemUpdated.next(null));
        break;
      }
      case WorkspaceSections.Actions: {
        const indexOfSection = BuildingBlockActionsData[0].children.findIndex((section) => {
          return section.children.some((item) => item.nodeData.id === data.id);
        });

        const indexOfItem = BuildingBlockActionsData[0].children[indexOfSection].children.findIndex(
          (item) => {
            return item.nodeData.id === data.id;
          },
        );

        BuildingBlockActionsData[0].children[indexOfSection].children.splice(indexOfItem, 1);
        this.saveTemplateService.isSaved.next(true);
        this.catalogActionApiService
          .deleteActionFromCatalog(data.id)
          .subscribe(() => this.spinService.isItemUpdated.next(null));
        break;
      }
      case WorkspaceSections.Conditions: {
        const indexOfSection = BuildingBlockConditionsData[0].children.findIndex((section) => {
          return section.children.some((item) => item.nodeData.id === data.id);
        });
        const indexOfItem = BuildingBlockConditionsData[0].children[
          indexOfSection
        ].children.findIndex((item) => {
          return item.nodeData.id === data.id;
        });

        BuildingBlockConditionsData[0].children[indexOfSection].children.splice(indexOfItem, 1);
        this.saveTemplateService.isSaved.next(true);
        this.catalogConditionApiService
          .deleteConditionFromCatalog(data.id)
          .subscribe(() => this.spinService.isItemUpdated.next(null));
        break;
      }
      default: {
        break;
      }
    }
  }
}
