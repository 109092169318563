<app-header></app-header>
<div class="content">
  <router-outlet></router-outlet>
</div>
<app-footer></app-footer>

<div class="spin" *ngIf="isSpinVisible">
  <nz-spin nzSimple [nzIndicator]="indicatorTemplate"></nz-spin>
</div>

<ng-template #indicatorTemplate><em class="spin__icon" nz-icon nzType="loading"></em></ng-template>
