import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { environment } from 'src/environments/environment';
import { LANGUAGE_KEY } from './shared/constants/local-storage-keys';
import { AutoLogoutService } from './shared/services/auto-logout/auto-logout.service';
import { LineService } from './shared/services/line/line.service';
import { SpinService } from './shared/services/spin/spin.service';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  @HostListener('document:mouseover')
  @HostListener('document:click')
  @HostListener('document:mouseout')
  @HostListener('document:keydown')
  @HostListener('document:keyup')
  @HostListener('document:keypress')
  resetInterval(): void {
    this.autoLogoutService.reset();
  }

  @HostListener('document:click')
  resetLines(): void {
    this.lineService.setAllLinesToDefaultState();
  }

  public isSpinVisible: boolean = false;

  constructor(
    private translateService: TranslateService,
    private autoLogoutService: AutoLogoutService,
    private spinService: SpinService,
    private cdr: ChangeDetectorRef,
    private lineService: LineService,
  ) {}

  ngOnInit(): void {
    if (localStorage.getItem(LANGUAGE_KEY)) {
      this.translateService.use(localStorage.getItem(LANGUAGE_KEY));
    } else {
      localStorage.setItem(LANGUAGE_KEY, environment.defaultLocale);
      this.translateService.use(environment.defaultLocale);
    }
    this.spinService.isSpinVisible.pipe(untilDestroyed(this)).subscribe((data) => {
      this.isSpinVisible = data;
      this.cdr.markForCheck();
    });
  }
}
