import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProductDto } from '../../../interfaces/product/product.interface';
import { EnvConfigurationService } from '../../environment/env-configuration-service.service';

@Injectable({
  providedIn: 'root',
})
export class CatalogProductApiService {
  private url = this.envConfigurationService.configuration.BASE_URL;

  constructor(
    private httpClient: HttpClient,
    private envConfigurationService: EnvConfigurationService,
  ) {}

  public getCatalogProducts(): Observable<ProductDto[]> {
    return this.httpClient.get<ProductDto[]>(`${this.url}/product/catalog/1`);
  }

  public saveProductToCatalog(item: ProductDto): Observable<ProductDto> {
    return this.httpClient.put<ProductDto>(`${this.url}/product-catalog/product/1`, item);
  }

  public deleteProductFromCatalog(id: number): Observable<null> {
    return this.httpClient.delete<null>(`${this.url}/product-catalog/product/${id}`);
  }
}
