import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class ApplyTokenInterceptor implements HttpInterceptor {
  constructor(private cookie: CookieService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const _a = this.cookie.get('_a');

    if (_a) {
      request = this.updateHeaders(request, _a);
    }

    return next.handle(request);
  }

  private updateHeaders(request: HttpRequest<any>, _a: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${_a}`,
      },
    });
  }
}
