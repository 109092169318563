import {
  PopConfirmContent,
  PopConfirmTypesEnum,
} from '../interfaces/modal/pop-confirm-modal.interface';

export const Coexistence = [
  { label: 'catalogue-panel.work_space.modal.always', value: 'always' },
  { label: 'catalogue-panel.work_space.modal.itemized', value: 'itemized' },
  { label: 'catalogue-panel.work_space.modal.specific_itemized', value: 'specific_itemized' },
  { label: 'catalogue-panel.work_space.modal.never', value: 'never' },
  { label: 'catalogue-panel.work_space.modal.specific_always', value: 'specific_always' },
  { label: 'catalogue-panel.work_space.modal.specific_never', value: 'specific_never' },
];
export const ChannelFormData = [
  { label: 'catalogue-panel.work_space.modal.print', value: 'print', checked: false },
  { label: 'catalogue-panel.work_space.modal.online_store', value: 'onlineStore', checked: false },
  { label: 'catalogue-panel.work_space.modal.store', value: 'store', checked: false },
  { label: 'catalogue-panel.work_space.modal.mobile_app', value: 'mobileApp', checked: false },
];

export enum PromotionCoexistence {
  Always = 'always',
  Itemized = 'itemized',
  SpecificItemized = 'specific_itemized',
  Never = 'never',
  SpecificAlways = 'specific_always',
  SpecificNever = 'specific_never',
}

export enum PromotionCoexistenceLabels {
  Always = 'always',
  Itemized = 'itemized',
  Never = 'never',
  SpecificAlways = 'specific always',
  SpecificNever = 'specific never',
  SpecificItemized = 'specific itemized',
}

export enum PromotionCoexistenceDto {
  Always = 'ALWAYS',
  Itemized = 'ITEMIZED',
  SpecificItemized = 'SPECIFIC_ITEMIZED',
  Never = 'NEVER',
  SpecificAlways = 'SPECIFIC_ALWAYS',
  SpecificNever = 'SPECIFIC_NEVER',
}

export const promotionCoexistenceToDto = {
  [PromotionCoexistence.Always]: PromotionCoexistenceDto.Always,
  [PromotionCoexistence.Itemized]: PromotionCoexistenceDto.Itemized,
  [PromotionCoexistence.SpecificItemized]: PromotionCoexistenceDto.SpecificItemized,
  [PromotionCoexistence.Never]: PromotionCoexistenceDto.Never,
  [PromotionCoexistence.SpecificAlways]: PromotionCoexistenceDto.SpecificAlways,
  [PromotionCoexistence.SpecificNever]: PromotionCoexistenceDto.SpecificNever,
};

export const dtoToPromotionCoexistence = {
  [PromotionCoexistenceDto.Always]: PromotionCoexistence.Always,
  [PromotionCoexistenceDto.Itemized]: PromotionCoexistence.Itemized,
  [PromotionCoexistenceDto.SpecificItemized]: PromotionCoexistence.SpecificItemized,
  [PromotionCoexistenceDto.Never]: PromotionCoexistence.Never,
  [PromotionCoexistenceDto.SpecificAlways]: PromotionCoexistence.SpecificAlways,
  [PromotionCoexistenceDto.SpecificNever]: PromotionCoexistence.SpecificNever,
};

export const promotionCoexistenceToLabels = {
  [PromotionCoexistence.Always]: PromotionCoexistenceLabels.Always,
  [PromotionCoexistence.Never]: PromotionCoexistenceLabels.Never,
  [PromotionCoexistence.SpecificAlways]: PromotionCoexistenceLabels.SpecificAlways,
  [PromotionCoexistence.SpecificNever]: PromotionCoexistenceLabels.SpecificNever,
  [PromotionCoexistence.Itemized]: PromotionCoexistenceLabels.Itemized,
  [PromotionCoexistence.SpecificItemized]: PromotionCoexistenceLabels.SpecificItemized,
};

export enum Channel {
  Print = 'print',
  OnlineStore = 'onlineStore',
  Store = 'store',
  MobileApp = 'mobileApp',
}

export enum ChannelDto {
  Print = 'PRINT',
  OnlineStore = 'ONLINE_STORE',
  Store = 'STORE',
  MobileApp = 'MOBILE_APP',
}

export const channelToDto = {
  [Channel.Print]: ChannelDto.Print,
  [Channel.OnlineStore]: ChannelDto.OnlineStore,
  [Channel.Store]: ChannelDto.Store,
  [Channel.MobileApp]: ChannelDto.MobileApp,
};

export const dtoToChannel = {
  [ChannelDto.Print]: Channel.Print,
  [ChannelDto.OnlineStore]: Channel.OnlineStore,
  [ChannelDto.Store]: Channel.Store,
  [ChannelDto.MobileApp]: Channel.MobileApp,
};

export enum TemplateStatusDto {
  Template = 'TEMPLATE',
}

export enum Status {
  Draft = 'Draft',
  Review = 'Review',
  PreLaunch = 'Pre-launch',
  Active = 'Active',
  Disabled = 'Disabled',
  Finished = 'Finished',
}

export enum StatusDto {
  Draft = 'DRAFT',
  Review = 'REVIEW',
  PreLaunch = 'PRE_LAUNCH',
  Active = 'ACTIVE',
  Disabled = 'DISABLED',
  Finished = 'FINISHED',
}

export const statusToDto = {
  [Status.Draft]: StatusDto.Draft,
  [Status.Review]: StatusDto.Review,
  [Status.PreLaunch]: StatusDto.PreLaunch,
  [Status.Active]: StatusDto.Active,
  [Status.Disabled]: StatusDto.Disabled,
  [Status.Finished]: StatusDto.Finished,
};

export const dtoToStatus = {
  [StatusDto.Draft]: Status.Draft,
  [StatusDto.Review]: Status.Review,
  [StatusDto.PreLaunch]: Status.PreLaunch,
  [StatusDto.Active]: Status.Active,
  [StatusDto.Disabled]: Status.Disabled,
  [StatusDto.Finished]: Status.Finished,
};

export const replacePromotionPopConfirmContent: PopConfirmContent = {
  title: 'catalogue-panel.work_space.confirmation_popup.change_campaign_popup.title',
  body: 'catalogue-panel.work_space.confirmation_popup.change_campaign_popup.confirm_replace_campaign',
  additionalInfo:
    'catalogue-panel.work_space.confirmation_popup.change_campaign_popup.campaign_will_be_automatically_saved',
  cancel: 'catalogue-panel.work_space.confirmation_popup.change_campaign_popup.cancel',
  confirm: 'catalogue-panel.work_space.confirmation_popup.change_campaign_popup.replace',
  type: PopConfirmTypesEnum.Replace,
};

export const createInstancePopConfirmContent: PopConfirmContent = {
  title: 'catalogue-panel.work_space.confirmation_popup.create_instance_popup.title',
  body: 'catalogue-panel.work_space.confirmation_popup.create_instance_popup.body',
  cancel: 'catalogue-panel.work_space.confirmation_popup.create_instance_popup.modify_template',
  confirm: 'catalogue-panel.work_space.confirmation_popup.create_instance_popup.create_instance',
  type: PopConfirmTypesEnum.InstanceOrTemplate,
};

export const createPromotionInstanceForCampaign: PopConfirmContent = {
  title:
    'catalogue-panel.work_space.confirmation_popup.create_promotion_instance_for_campaign.title',
  body: 'catalogue-panel.work_space.confirmation_popup.create_promotion_instance_for_campaign.body',
  cancel:
    'catalogue-panel.work_space.confirmation_popup.create_promotion_instance_for_campaign.modify_template',

  confirm:
    'catalogue-panel.work_space.confirmation_popup.create_promotion_instance_for_campaign.create_instance',
  type: PopConfirmTypesEnum.InstanceOrIncludeToCampaign,
};
