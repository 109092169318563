import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isItemInArray',
})
export class IsItemInArrayPipe implements PipeTransform {
  transform(dragItemIds: any, item: any, parentId: number): boolean {
    const dragItemNativeElementIds = dragItemIds?.split('-');
    if (dragItemNativeElementIds?.length) {
      const dragItemParenId = +dragItemNativeElementIds[dragItemNativeElementIds?.length - 1];

      const dragItemId = +dragItemNativeElementIds[0];

      return dragItemId !== item?.id && dragItemParenId === parentId;
    }
    return false;
  }
}
