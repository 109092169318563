import { ElementRef, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import 'leader-line';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { WorkspaceSections } from '../../constants/work-space.data';
import { isInPage } from '../../helpers/node-is-on-page.function';
import { LinkedItemsData } from '../../interfaces/promotion/linked-items-data.interface';
import { LinkApiService } from '../api/workspace/link-api.service';
import { AuthService } from '../auth/auth.service';
import { generateRemoveIcon, linesConfig } from './lines.config';
import { ModalService } from '../modal/modal.service';

declare const LeaderLine: any;

@Injectable({
  providedIn: 'root',
})
export class LineService {
  public lines: any[] = [];

  public linesRepresented: HTMLElement[];

  public clickedItemId = new BehaviorSubject(null);

  public inputRef: ElementRef;

  public linkedItems: { start: HTMLDivElement; end: HTMLDivElement }[] = [];

  public linkedItemsData: LinkedItemsData[] = [];

  public clickedLinkTitle = new BehaviorSubject(null);

  public linkedElements: any[] = [];

  public savedLinks: LinkedItemsData[] = [];

  public startLinkType: string = '';

  public endLinkType: string = '';

  public startLinkId: number = null;

  public endLinkId: number = null;

  public renderer: Renderer2;

  constructor(
    private linkApiService: LinkApiService,
    rendererFactory: RendererFactory2,
    private authService: AuthService,
    private modalService: ModalService,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  public drawLine(
    campaignId: number,
    promotionId: number,
    start: HTMLDivElement,
    end: HTMLDivElement,
    input: ElementRef,
    id: number,
  ): void {
    if (this.lines.some((el) => el.start === start && el.end === end)) {
      return;
    }

    const index = this.linkedItems.findIndex(
      (el) => el.start.id === start.id && el.end.id === end.id,
    );
    if (index >= 0) {
      this.linkedItems.splice(index, 1, { start, end });
    }

    const title =
      this.startLinkType === WorkspaceSections.Products ||
      this.endLinkType === WorkspaceSections.Products
        ? '0'
        : 'AND';

    const linkedItemsData = {
      promotionId,
      campaignId,
      id,
      startId: start.id,
      endId: end.id,
      title,
    };

    this.inputRef = input;
    const line = new LeaderLine(start, end, {
      startPlug: 'behind',
      endPlug: 'behind',
      color: linesConfig.defaultColor,
      middleLabel: '',
      size: linesConfig.size,
      dash: 'dashed',
    });

    if (!this.linkedItemsData.some((el) => el.startId === start.id && el.endId === end.id)) {
      this.linkedItemsData.push(linkedItemsData);
    }

    line.dataId = id;
    this.lines.push(line);
    this.linesRepresented = Array.from(
      document.getElementsByClassName('leader-line') as HTMLCollectionOf<HTMLElement>,
    );

    this.linesRepresented.forEach((representedLineItem, i) => {
      this.lines.forEach((lineItem) => {
        lineItem.id = line._id - 1;
      });

      if (!representedLineItem.hasAttribute('id')) {
        representedLineItem.setAttribute('id', `${i}`);
        representedLineItem.setAttribute(
          'style',
          `${representedLineItem.style.cssText}pointer-events: all !important`,
        );
        if (this.authService.setUserRights(campaignId, promotionId))
          this.createEventListener(i, linkedItemsData.id);
      }
    });
  }

  public createRerenderLineObserver() {
    const observer = new MutationObserver(() => {
      this.render();
    });

    const cataloguePanelNode = document.querySelector('app-catalogue-panel');
    if (cataloguePanelNode) {
      observer.observe(cataloguePanelNode, {
        subtree: true,
        attributes: true,
      });
    }
  }

  public render(): void {
    setTimeout(() => {
      this.lines.forEach((line) => {
        line.position();
      });
    }, 0);
  }

  public deleteAllLines(): void {
    this.savedLinks = cloneDeep(this.linkedItemsData);
    this.lines.forEach((line) => line.remove());
    this.lines = [];
    this.linesRepresented = [];
    this.linkedItemsData = [];
  }

  public saveTitle(title: string): void {
    if (!title.trim()) {
      if (this.lines[this.clickedItemId.getValue()].middleLabel) {
        title = this.lines[this.clickedItemId.getValue()].middleLabel;
      } else {
        title = 'click';
      }
    }
    const linkedItemIdForRemove = this.lines.findIndex((linkedItem) => {
      return (
        this.lines[this.clickedItemId.getValue()].start.isEqualNode(linkedItem.start) &&
        this.lines[this.clickedItemId.getValue()].end.isEqualNode(linkedItem.end)
      );
    });

    this.linkApiService
      .getLinkById(this.linkedItemsData[linkedItemIdForRemove].id)
      .pipe(
        mergeMap((res) => {
          if (res?.operator) {
            res.operator = title;
          } else {
            res.value = +title;
          }
          return this.linkApiService.updateLink(res);
        }),
      )
      .subscribe();

    this.clickedLinkTitle.next(title);
    this.lines[this.clickedItemId.getValue()].middleLabel = title;
    this.linkedItemsData[this.clickedItemId.getValue()].title = title;
    this.closeTitleInput();
  }

  public closeTitleInput(): void {
    this.setDefaultLinesZIndex();
    const foreignObject =
      this.linesRepresented[this.clickedItemId.getValue()].childNodes[1].childNodes[3];
    this.linesRepresented[this.clickedItemId.getValue()].childNodes[1].removeChild(foreignObject);
  }

  public removeLine(lines: any[] = []): void {
    if (lines.length) {
      lines.forEach(({ dataId }) => {
        const lineIndex = this.lines.findIndex((line) => {
          return +dataId === +line.dataId;
        });

        const linkedItemIdForRemove = this.linkedItems.findIndex((linkedItem) => {
          return (
            this.lines[lineIndex].start.isEqualNode(linkedItem.start) &&
            this.lines[lineIndex].end.isEqualNode(linkedItem.end)
          );
        });

        this.lines[lineIndex].remove();
        this.lines.splice(lineIndex, 1);
        this.linkedItemsData.splice(lineIndex, 1);
        this.linesRepresented.splice(lineIndex, 1);
        this.linkedItems.splice(linkedItemIdForRemove, 1);
      });
    } else {
      this.removeClickedLine(this.clickedItemId.getValue());
    }

    this.render();
  }

  private removeClickedLine(id: number): void {
    const linkedItemIdForRemove = this.linkedItems.findIndex((linkedItem) => {
      return (
        this.lines[id].start.isEqualNode(linkedItem.start) &&
        this.lines[id].end.isEqualNode(linkedItem.end)
      );
    });

    this.linkApiService.deleteLink(this.linkedItemsData[linkedItemIdForRemove].id).subscribe();
    this.linkedItems.splice(linkedItemIdForRemove, 1);
    this.linkedItemsData.splice(linkedItemIdForRemove, 1);
    this.lines[id].remove();
    this.lines.splice(id, 1);
    this.linesRepresented.splice(id, 1);
    this.deleteAllLines();
    this.render();
    this.linkedItemsData = this.savedLinks;
  }

  private createEventListener(index: number, linkId: number): void {
    const representedLine = this.linesRepresented[index];
    const currentLine = this.lines[index];

    const allG = representedLine.querySelectorAll('g');
    const gElement = allG[allG.length - 1];

    this.renderer.setStyle(gElement, 'pointer-events', 'all');
    this.renderer.setStyle(gElement, 'cursor', 'pointer');
    this.renderer.setStyle(representedLine, 'pointer-events', 'none');

    gElement.addEventListener('click', (e: any) => {
      e.stopPropagation();
      if (!currentLine.middleLabel) {
        currentLine.middleLabel = linesConfig.defaultLabel;
        const xCoord = parseInt(representedLine.querySelector('text').getAttribute('x'), 10);
        const yCoord = parseInt(representedLine.querySelector('text').getAttribute('y'), 10);

        currentLine.color = linesConfig.selectedColor;

        this.lines.forEach((line, i) => {
          if (line.dataId !== currentLine.dataId) {
            this.setDefaultLine(line, this.linesRepresented[i]);
          }
        });
        representedLine.insertAdjacentHTML(
          'beforeend',
          generateRemoveIcon(xCoord, yCoord, !!this.linkedItemsData[index]?.campaignId),
        );
        this.modalService.currentCampaignLineId = null;
        this.createRemoveLinkEventListener(linkId, representedLine);
      } else {
        this.setDefaultLine(currentLine, representedLine);
      }
      this.render();
    });
  }

  public setAllLinesToDefaultState() {
    this.lines.forEach((line, i) => {
      this.setDefaultLine(line, this.linesRepresented[i]);
    });
  }

  private setDefaultLine(line: any, representedLine: HTMLElement) {
    line.middleLabel = '';
    line.color = linesConfig.defaultColor;

    representedLine.querySelector('image')?.remove();
  }

  private createRemoveLinkEventListener(id: number, representedLine: HTMLElement) {
    const representedImage = representedLine.querySelector('image');
    this.renderer.setStyle(representedImage, 'pointer-events', 'all');
    this.renderer.setStyle(representedImage, 'cursor', 'pointer');

    representedImage.addEventListener('click', () => {
      const lineToRemove = this.lines.find((line) => line.dataId === id);
      this.linkApiService.deleteLink(id).subscribe(() => {
        this.removeLine([lineToRemove]);
      });
    });
  }

  public setDefaultLinesZIndex(): void {
    this.linesRepresented.forEach((lineRepresented) => {
      lineRepresented.style.zIndex = '1';
    });
  }

  public checkLinkedElements(): void {
    this.lines.forEach((line) => {
      if (
        !this.linkedItems.some((item) => {
          return item.end.isEqualNode(line.end) && item.start.isEqualNode(line.start);
        })
      ) {
        this.linkedItems.push({ start: line.start, end: line.end });
      }
    });
  }

  public deleteUnlinkedLines(
    itemId: string,
    type: string,
    promotionId: number = null,
    campaignId: number = null,
  ): void {
    const linesForDeleteIndexes: number[] = [];
    const linesForDelete = this.linkedItemsData.reduce((acc, curr, index) => {
      if (
        curr.startId ===
          `${itemId}-${type}-${promotionId ? `${promotionId}-` : `-${campaignId}`}` ||
        curr.endId === `${itemId}-${type}-${promotionId ? `${promotionId}-` : `-${campaignId}`}`
      ) {
        acc.push(this.linkApiService.deleteLink(curr.id));
        linesForDeleteIndexes.push(index);
      }
      return acc;
    }, []);
    forkJoin(linesForDelete).subscribe(() => {
      linesForDeleteIndexes.forEach((index) => {
        this.linkedItems.splice(index, 1);
      });
    });

    this.deleteLinesAsync();

    this.linkedItemsData = this.linkedItemsData.filter((el) => {
      return !(
        el.startId === `${itemId}-${type}-${promotionId ? `${promotionId}-` : `-${campaignId}`}` ||
        el.endId === `${itemId}-${type}-${promotionId ? `${promotionId}-` : `-${campaignId}`}`
      );
    });
  }

  public deleteLinesAsync(): void {
    const linesToRemove: any[] = [];
    setTimeout(() => {
      this.lines.forEach((line) => {
        if (!isInPage(line.start) || !isInPage(line.end)) {
          linesToRemove.push(line);
        }
      });
      if (linesToRemove.length) {
        this.removeLine(linesToRemove);
      }
    }, 0);
  }
}
