export enum UserRolesDto {
  StoreEmployee = 'EMPLOYEE',
  CentralEmployee = 'CENTRAL_EMPLOYEE',
  Admin = 'ADMIN',
  AdvancedUser = 'ADVANCED_USER',
}

export enum UserRoles {
  StoreEmployee = 'Employee',
  CentralEmployee = 'Central employee',
  Admin = 'Admin',
  AdvancedUser = 'Advanced user',
}

export const DtoToUserRoles = {
  [UserRolesDto.StoreEmployee]: UserRoles.StoreEmployee,
  [UserRolesDto.CentralEmployee]: UserRoles.CentralEmployee,
  [UserRolesDto.Admin]: UserRoles.Admin,
  [UserRolesDto.AdvancedUser]: UserRoles.AdvancedUser,
};
