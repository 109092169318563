import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { HeaderComponent } from './header.component';

import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { TranslateModule } from '@ngx-translate/core';
import { InternationalizeModule } from './internationalize/internationalize.module';


@NgModule({
  declarations: [HeaderComponent],
  imports: [
    CommonModule,
    NzPopoverModule,
    RouterModule,
    NzButtonModule,
    NzIconModule,
    TranslateModule,
    InternationalizeModule
  ],
  exports: [HeaderComponent],
})
export class HeaderModule { }
