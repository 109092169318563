import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SaveTemplateService {
  public isSaved = new Subject<boolean>();

  public isItemDeleted = new Subject<null>();
}
