<ng-container [ngSwitch]="selectMode">
  <ng-container *ngSwitchCase="'multiple'">
    <nz-select
      class="promotion-form-container-row__select"
      [formControl]="control"
      [nzOptions]="values"
      (nzBlur)="onBlur()"
      (nzFocus)="onFocus()"
      [nzPlaceHolder]="placeholder | translate"
      [nzDisabled]="disabled"
      [nzMode]="selectMode"
      nzSuffixIcon="caret-down"
    >
    </nz-select>
    <span class="error" *ngIf="ngControl?.control?.errors && touched">
      {{ currentErrors | getError: errorMessages | translate }}
    </span>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <nz-select
      class="promotion-form-container-row__select"
      [formControl]="control"
      [nzOptions]="values"
      [ngModel]="selectValue"
      (nzBlur)="onBlur()"
      (nzFocus)="onFocus()"
      [nzPlaceHolder]="placeholder | translate"
      [nzDisabled]="disabled"
      [nzMode]="selectMode"
      nzSuffixIcon="caret-down"
    >
    </nz-select>
    <span class="error" *ngIf="ngControl?.control?.errors && touched">
      {{ currentErrors | getError: errorMessages | translate }}
    </span></ng-container
  >
</ng-container>
