import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Pipe({
  name: 'getError',
})
export class GetErrorPipe implements PipeTransform {
  transform(value: ValidationErrors, errors: any): string {
    const keys = Object.keys(value || {});

    const key = keys.length && keys[0];

    if (value?.incorrectJsonValue) {
      return value.message;
    }
    return key ? errors[key] : '';
  }
}
