import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  ControlValueAccessor,
  NgControl,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
  ValidationErrors,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NzSelectOptionInterface } from 'ng-zorro-antd/select';
import { startWith } from 'rxjs/operators';
import { ErrorMessagesInterface } from '../../interfaces/form/error-messages.interface';
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectComponent
  implements OnInit, AfterViewInit, DoCheck, ControlValueAccessor, AfterContentChecked
{
  @Input() errorMessages: ErrorMessagesInterface = {};

  @Input() selectValue: string | number | any[];

  @Input() values: NzSelectOptionInterface[];

  @Input() placeholder: string = 'common.placeholders.select';

  @Input() disabled: boolean = false;

  @Input() public selectMode: 'multiple' | 'tags' | 'default' = 'default';

  @Output() focus: EventEmitter<null> = new EventEmitter();

  @Output() blur: EventEmitter<null> = new EventEmitter();

  public ngControl: NgControl;

  public control: UntypedFormControl;

  public touched = false;

  public onChange = (value: any) => {};

  public onTouched = () => {};

  public currentErrors: null | ValidationErrors | undefined = null;

  constructor(
    private injector: Injector,
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
  ) {}

  public ngAfterViewInit(): void {
    this.ngControl = this.injector.get(NgControl);
    this.ngControl.control?.statusChanges
      .pipe(startWith(this.ngControl?.control?.status), untilDestroyed(this))
      .subscribe(() => {
        this.currentErrors = this.ngControl?.control?.errors;

        this.cdr.detectChanges();
      });
    this.cdr.markForCheck();
  }

  public ngOnInit(): void {
    this.values = this.values.map((value) => {
      return { ...value, label: this.translateService.instant(value.label as string) };
    });
    this.control = new UntypedFormControl('');
    this.control.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      this.onChange(value);
    });
  }

  public ngDoCheck(): void {
    this.checkTouchedStatus();
  }

  public ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  public onBlur(): void {
    this.onTouched();
    this.blur.emit(null);
  }

  public onFocus(): void {
    this.focus.emit(null);
  }

  public writeValue(obj: any): void {
    this.control?.setValue(obj);
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  private checkTouchedStatus(): void {
    this.touched = Boolean(this.ngControl?.control?.touched);
    this.cdr.markForCheck();
  }

  public setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }
}
