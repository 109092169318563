import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'limiter',
})
export class LimiterPipe implements PipeTransform {
  transform(value: string, limit: number): string {
    if (value) {
      return value.length > limit ? value.substr(0, limit - 1) + '...' : value;
    }
    return '';
  }
}
