import { Injectable, Injector } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { DataTypesDto } from '../../interfaces/working-space/work-space-data-type.interface';

import { WorkSpaceDnDItem } from '../../interfaces/working-space/work-space-data.interface';
import { DndApiService } from '../api/workspace/dnd-api.service';
import { ModalService } from '../modal/modal.service';

@Injectable({
  providedIn: 'root',
})
export class DragonDropService {
  public isHighlighted = new BehaviorSubject<boolean>(false);

  public dropContainerCoords = new BehaviorSubject<any>(null);

  public dropItemCoords = new BehaviorSubject<any>(null);

  public isDropFinished = new BehaviorSubject<WorkSpaceDnDItem>(null);

  public isProductsHighlighted = new BehaviorSubject<boolean>(false);

  public isConditionsHighlighted = new BehaviorSubject<boolean>(false);

  public isActionsHighlighted = new BehaviorSubject<boolean>(false);

  public dragItemIds = new BehaviorSubject<any>(null);

  public isWorkspaceButtonsHighlighted = new BehaviorSubject<boolean>(false);

  public isCampaignHighlighted = new BehaviorSubject<boolean>(false);

  public isOnlyButtonsHighlighted = new BehaviorSubject<boolean>(false);

  public isBasketHighlighted = new BehaviorSubject<boolean>(false);

  public isPromotionHighlighted = new BehaviorSubject<boolean>(false);

  public isDisableDrag = new BehaviorSubject<boolean>(false);

  constructor(private dndApiService: DndApiService, private injector: Injector) {}

  public dndBuildingBlock(blockType: DataTypesDto, blockId: string | number) {
    const modalService = this.injector.get(ModalService);
    if (modalService.previousPromotionId && modalService.currentPromotionId) {
      return this.dndApiService.dndFromPromoToPromo(
        blockType,
        blockId,
        modalService.previousPromotionId,
        modalService.currentPromotionId,
      );
    } else if (
      modalService.currentPromotionId &&
      !modalService.previousCampaignId &&
      !modalService.previousPromotionId
    ) {
      return this.dndApiService.dndFromCatalogueToPromo(
        blockType,
        blockId,
        modalService.currentPromotionId,
      );
    } else if (
      modalService.previousCampaignId &&
      !modalService.previousPromotionId &&
      modalService.currentPromotionId
    ) {
      return this.dndApiService.dndFromCampaignToPromo(
        blockType,
        blockId,
        modalService.previousCampaignId,
        modalService.currentPromotionId,
      );
    } else if (
      modalService.previousPromotionId &&
      modalService.currentCampaignId &&
      !modalService.currentPromotionId
    ) {
      return this.dndApiService.dndFromPromToCampaign(
        blockType,
        blockId,
        modalService.previousPromotionId,
        modalService.currentCampaignId,
      );
    } else {
      return this.dndApiService.dndFromCatalogueToCampaign(
        blockType,
        blockId,
        modalService.currentCampaignId,
      );
    }
    return null;
  }
}
