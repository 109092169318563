import { Pipe, PipeTransform } from '@angular/core';
import {
  testAreaBasketDropListData,
  testAreaPromotionsDropListData,
} from '../constants/drop-list-data';

@Pipe({
  name: 'isHighlight',
})
export class IsHighlightPipe implements PipeTransform {
  transform(value: string, isBasketHighlighted: boolean, isPromotionHighlighted: boolean): boolean {
    const uniqDndId = value.replace(/[^0-9]/g, '');

    const promotionDropListId = testAreaPromotionsDropListData.findIndex(
      (item) => item.replace(/[^0-9]/g, '') === uniqDndId,
    );
    const BasketDropListId = testAreaBasketDropListData.findIndex(
      (item) => item.replace(/[^0-9]/g, '') === uniqDndId,
    );
    return Boolean(
      (testAreaPromotionsDropListData[promotionDropListId] === value && isPromotionHighlighted) ||
        (testAreaBasketDropListData[BasketDropListId] === value && isBasketHighlighted),
    );
  }
}
