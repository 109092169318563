import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConditionDto } from '../../../interfaces/condition/condition.interface';
import { EnvConfigurationService } from '../../environment/env-configuration-service.service';

@Injectable({
  providedIn: 'root',
})
export class CatalogConditionApiService {
  private url = this.envConfigurationService.configuration.BASE_URL;

  constructor(
    private httpClient: HttpClient,
    private envConfigurationService: EnvConfigurationService,
  ) {}

  public getCatalogConditions(): Observable<ConditionDto[]> {
    return this.httpClient.get<ConditionDto[]>(`${this.url}/condition/catalog/1`);
  }

  public saveConditionToCatalog(item: ConditionDto): Observable<ConditionDto> {
    return this.httpClient.put<ConditionDto>(`${this.url}/condition-catalog/condition/1`, item);
  }

  public deleteConditionFromCatalog(id: number): Observable<null> {
    return this.httpClient.delete<null>(`${this.url}/condition-catalog/condition/${id}`);
  }
}
