import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvConfigurationInterface } from '../../interfaces/config/env-config.interface';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EnvConfigurationService {
  public configuration: EnvConfigurationInterface;
  private configUrl = 'assets/config/config.json';

  constructor(private http: HttpClient) {}
  public load(): Observable<EnvConfigurationInterface> {
    return this.http.get<EnvConfigurationInterface>(`${this.configUrl}`).pipe(
      tap((config) => {
        this.configuration = config;
      }),
    );
  }
}
