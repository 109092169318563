export enum CatalogueFilterStatuses {
  Closed = 'closed',
  Active = 'active',
  InProgress = 'in progress',
}

export enum CatalogueFilterStatusesDTO {
  Closed = 'CLOSED',
  Active = 'ACTIVE',
  InProgress = 'IN_PROGRESS',
}

export const catalogueFilterStatusesToDto = {
  [CatalogueFilterStatuses.Closed]: CatalogueFilterStatusesDTO.Closed,
  [CatalogueFilterStatuses.Active]: CatalogueFilterStatusesDTO.Active,
  [CatalogueFilterStatuses.InProgress]: CatalogueFilterStatusesDTO.InProgress,
};
