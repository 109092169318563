import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { UserData } from '../interfaces/auth/auth.interface';
import { ModalService } from '../services/modal/modal.service';
import { LineService } from '../services/line/line.service';
import { DtoToUserRoles, UserRoles, UserRolesDto } from '../constants/user.data';
import { workSpaceContent } from '../constants/work-space.data';
import { EditorNavigationService } from '../services/editor-navigation/editor-navigation.service';
import { AuthService } from '../services/auth/auth.service';

@UntilDestroy()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
  private subscription = new Subscription();

  public currentRoute: string = '/dashboard';

  public isNavigationVisible = false;

  public userInfo: UserData = null;

  public userRolesTransformer = DtoToUserRoles;

  public userRoles = UserRoles;

  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef,
    private cookies: CookieService,
    private modalService: ModalService,
    private lineService: LineService,
    private editorNavigationService: EditorNavigationService,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.router.events
        .pipe(
          untilDestroyed(this),
          filter((event) => event instanceof NavigationEnd),
        )
        .subscribe((event: any) => {
          this.lineService.deleteAllLines();

          if (event.url !== '/') {
            if (event.url.includes('/editor')) {
              this.currentRoute = '/editor';
            } else {
              this.currentRoute = event.url;
            }
          }

          if (this.cookies.get('userInfo')) {
            this.userInfo = this.authService.userInfo.getValue();

            this.userInfo.roleName =
              this.userRolesTransformer[this.userInfo.roles[0].name as UserRolesDto];
          }

          this.isNavigationVisible = event?.urlAfterRedirects !== '/login';
          this.cdr.detectChanges();
        }),
    );
  }

  public navigateEditor(): void {
    this.editorNavigationService.openEditor();
  }

  public logOut(): void {
    this.cookies.deleteAll();
    this.modalService.closeModal();
    this.lineService.deleteAllLines();
    this.lineService.savedLinks = [];
    this.router.navigate(['login']);

    workSpaceContent.splice(0, workSpaceContent?.length);
  }
}
