import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { WORKSPACE_URL_KEY } from '../constants/local-storage-keys';

@Component({
  selector: 'app-header',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotFoundComponent implements OnInit {
  public ngOnInit(): void {
    localStorage.removeItem(WORKSPACE_URL_KEY);
  }
}
