import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActionDto } from '../../../interfaces/action/action.interface';
import { EnvConfigurationService } from '../../environment/env-configuration-service.service';

@Injectable({
  providedIn: 'root',
})
export class CatalogActionApiService {
  private url = this.envConfigurationService.configuration.BASE_URL;

  constructor(
    private httpClient: HttpClient,
    private envConfigurationService: EnvConfigurationService,
  ) {}

  public getCatalogActions(): Observable<ActionDto[]> {
    return this.httpClient.get<ActionDto[]>(`${this.url}/action/catalog/1`);
  }

  public saveActionToCatalog(item: ActionDto): Observable<ActionDto> {
    return this.httpClient.put<ActionDto>(`${this.url}/action-catalog/action/1`, item);
  }

  public deleteActionFromCatalog(id: number): Observable<null> {
    return this.httpClient.delete<null>(`${this.url}/action-catalog/action/${id}`);
  }
}
