import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { languages } from '../../constants/internationalize.data';
import { LANGUAGE_KEY } from '../../constants/local-storage-keys';
import { LanguageOptionInterface } from '../../interfaces/internationalize/internationalize.interface';

@Component({
  selector: 'app-internationalize',
  templateUrl: './internationalize.component.html',
  styleUrls: ['./internationalize.component.scss'],
})
export class InternationalizeComponent implements OnInit {
  public languages: LanguageOptionInterface[] = languages;
  public languagesControl: any;

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.languagesControl = languages.find(
      (item) => item.value == localStorage.getItem(LANGUAGE_KEY),
    ).value;
  }

  public setLanguage(event: any) {
    localStorage.setItem(LANGUAGE_KEY, event);
    this.translateService.use(event);
    this.languagesControl = languages.find(
      (item) => item.value == localStorage.getItem(LANGUAGE_KEY),
    ).value;
  }
}
