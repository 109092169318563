<footer class="footer">
  <p class="footer__version">
    <span class="footer__version-number" nz-popover [nzPopoverContent]="versionsMenu">Version</span>
  </p>
</footer>

<ng-template #versionsMenu>
  <p class="label">
    Frontend version: <span class="version">{{ versions.frontendAppVersion }}</span>
  </p>
  <p class="label">
    Backend version: <span class="version">{{ versions.backendAppVersion }}</span>
  </p>
  <p class="label">
    Authorization service version: <span class="version">{{ versions.authAppVersion }}</span>
  </p>
  <p class="label">
    Discovery service version: <span class="version">{{ versions.discAppVersion }}</span>
  </p>
  <p class="label">
    Gateway service version: <span class="version">{{ versions.gatewayAppVersion }}</span>
  </p>
  <p class="label">
    GCM pricing service version:
    <span class="version">{{ versions.gcmPricingServiceVersion }}</span>
  </p>
</ng-template>
