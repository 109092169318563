import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SelectModule } from '../../forms/select/select.module';
import { InternationalizeComponent } from './internationalize.component';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzSelectModule } from 'ng-zorro-antd/select';



@NgModule({
  declarations: [InternationalizeComponent],
  imports: [
    CommonModule,
    TranslateModule,
    SelectModule,
    NzIconModule,
    ReactiveFormsModule,
    NzSelectModule,
    FormsModule
  ],
  exports: [InternationalizeComponent]
})
export class InternationalizeModule { }
