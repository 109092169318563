/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CheckUserInfoGuard } from './shared/guards/check-user-info.guard';
import { CheckAccessTokenGuard } from './shared/guards/check-access-token.guard';
import { NotFoundComponent } from './shared/not-found/not-found.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'editor',
    loadChildren: () =>
      import('./modules/promotion-editor/promotion-editor.module').then(
        (m) => m.PromotionEditorModule,
      ),
    canActivate: [CheckUserInfoGuard],
  },
  {
    path: 'loyalty',
    loadChildren: () =>
      import('./modules/loyalty/loyalty-page.module').then((m) => m.LoyaltyPageModule),
    canActivate: [CheckUserInfoGuard],
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [CheckUserInfoGuard],
  },
  {
    path: 'simulator',
    loadChildren: () =>
      import('./modules/simulator/simulator.module').then((m) => m.SimulatorModule),
    canActivate: [CheckUserInfoGuard],
  },
  {
    path: 'login',
    loadChildren: () => import('./modules/login/login.module').then((m) => m.LoginModule),
    canActivate: [CheckAccessTokenGuard],
  },
  {
    path: '**',
    redirectTo: 'not-found-page',
  },
  {
    path: 'not-found-page',
    component: NotFoundComponent,
    canActivate: [CheckUserInfoGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
