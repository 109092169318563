import { Injectable } from '@angular/core';

import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SpinService {
  public isSpinVisible = new BehaviorSubject<boolean>(false);

  public isItemUpdated = new Subject<null>();
}
