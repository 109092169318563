import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { CookieService } from 'ngx-cookie-service';
import { EditorNavigationService } from '../services/editor-navigation/editor-navigation.service';

@Injectable({
  providedIn: 'root',
})
export class CheckAccessTokenGuard  {
  constructor(
    private cookies: CookieService,
    private editorNavigationService: EditorNavigationService,
  ) {}

  canActivate(): boolean {
    const _a = this.cookies.get('_a');

    if (_a) {
      this.editorNavigationService.openEditor();
      return false;
    }

    return true;
  }
}
