import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { WORKSPACE_URL_KEY } from '../../constants/local-storage-keys';
import { EntityTypeEnum } from '../../enums/entity-type.enum';
import { OpenedEntity } from '../../interfaces/working-space/work-space-events.interface';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class EditorNavigationService {
  constructor(private router: Router, private authService: AuthService) {}

  public openEntityInEditor(queryParams: OpenedEntity, isClearStoredParams = false): void {
    const storedParams = JSON.parse(localStorage.getItem(WORKSPACE_URL_KEY) || '{}');

    if (
      storedParams?.type === EntityTypeEnum.Promotion &&
      queryParams.type === EntityTypeEnum.Promotion &&
      !isClearStoredParams
    ) {
      const storedIdsArray = JSON.parse(storedParams?.ids);
      const ids = JSON.stringify(Array.from(new Set(storedIdsArray.concat(queryParams.ids))));
      this.navigateEditor({ ...queryParams, ids });
      return;
    }
    this.clearLocalStorage();
    this.navigateEditor({ ...queryParams, ids: JSON.stringify(queryParams.ids) });
  }

  public deleteEntityAndOpenEditor(id: string, queryParams: OpenedEntity): void {
    const storedParams = JSON.parse(localStorage.getItem(WORKSPACE_URL_KEY) || '{}');
    if (storedParams.type) {
      const storedIdsArray = JSON.parse(storedParams?.ids);
      localStorage.setItem(
        WORKSPACE_URL_KEY,
        JSON.stringify({
          type: storedParams.type,
          ids: JSON.stringify(storedIdsArray.filter((itemId: string) => itemId !== id)),
        }),
      );
    }
    this.openEntityInEditor(queryParams);
  }

  public openEditor(): void {
    const queryParams = JSON.parse(localStorage.getItem(WORKSPACE_URL_KEY) || '{}');
    this.navigateEditor(queryParams);
  }

  public clearAndOpenEditor(): void {
    this.clearLocalStorage();
    this.navigateEditor();
  }

  private navigateEditor(queryParams?: any): void {
    this.router.navigate(['editor'], {
      queryParams,
    });
  }

  private clearLocalStorage(): void {
    localStorage.removeItem(WORKSPACE_URL_KEY);
  }
}
