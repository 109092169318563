import { ChangeDetectionStrategy, Component } from '@angular/core';
import { version } from 'src/app-versions';

import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-footer',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  public releaseNumber = environment.releaseNumber;
  public versions = version;

  constructor() { }

}
