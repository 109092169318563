import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataTypesDto } from 'src/app/shared/interfaces/working-space/work-space-data-type.interface';
import { EnvConfigurationService } from '../../environment/env-configuration-service.service';

@Injectable({
  providedIn: 'root',
})
export class DndApiService {
  private url = this.envConfigurationService.configuration.BASE_URL;

  constructor(
    private httpClient: HttpClient,
    private envConfigurationService: EnvConfigurationService,
  ) {}

  public dndFromCatalogueToPromo(
    buildingBlockType: DataTypesDto,
    buildingBlockId: string | number,
    promotionId: string | number,
  ): Observable<any> {
    return this.httpClient.put<any>(`${this.url}/dnd/promo`, {
      buildingBlockType,
      buildingBlockId,
      promotionId,
    });
  }

  public dndFromPromoToPromo(
    buildingBlockType: DataTypesDto,
    buildingBlockId: string | number,
    startPromoId: string | number,
    endPromoId: string | number,
  ): Observable<any> {
    return this.httpClient.put<any>(`${this.url}/dnd/promo_promo`, {
      buildingBlockType,
      buildingBlockId,
      startPromoId,
      endPromoId,
    });
  }

  public dndFromPromToCampaign(
    buildingBlockType: DataTypesDto,
    buildingBlockId: string | number,
    startPromoId: string | number,
    endCampaignId: string | number,
  ): Observable<any> {
    return this.httpClient.put<any>(`${this.url}/dnd/promo_campaign`, {
      buildingBlockType,
      buildingBlockId,
      startPromoId,
      endCampaignId,
    });
  }

  public dndFromCatalogueToCampaign(
    buildingBlockType: DataTypesDto,
    buildingBlockId: string | number,
    campaignId: string | number,
  ): Observable<any> {
    return this.httpClient.put<any>(`${this.url}/dnd/campaign`, {
      buildingBlockType,
      buildingBlockId,
      campaignId,
    });
  }

  public dndFromCampaignToPromo(
    buildingBlockType: DataTypesDto,
    buildingBlockId: string | number,
    startCampaignId: string | number,
    endPromoId: string | number,
  ): Observable<any> {
    return this.httpClient.put<any>(`${this.url}/dnd/campaign_promo`, {
      buildingBlockType,
      buildingBlockId,
      startCampaignId,
      endPromoId,
    });
  }
}
