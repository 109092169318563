import { ElementRef, EventEmitter, Injectable, TemplateRef } from '@angular/core';

import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';

import { workSpaceContent } from '../../constants/work-space.data';

import { DragDrop } from '@angular/cdk/drag-drop';
import { Action } from '../../interfaces/action/action.interface';
import { WorkSpaceDataType } from '../../interfaces/working-space/work-space-data-type.interface';
import { WorkSpaceContent } from '../../interfaces/working-space/work-space-data.interface';
import { DragonDropService } from '../dragon-drop/dragon-drop.service';
import { CatalogueService } from '../catalogue/catalogue.service';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  public previousCampaignId: number = null;

  public previousPromotionId: number = null;

  public currentPromotionId: number = null;

  public currentCampaignId: number = null;

  public currentCampaignLineId: number = null;

  public currentPromotionLineId: number = null;

  public buildingBlockNames: string[] = [];

  public modalRefs: NzModalRef[] = [];

  public modalClosed = new EventEmitter();

  public mouseUpEvent = new Event('mouseup');

  constructor(
    private modal: NzModalService,
    private dragDrop: DragDrop,
    private dragAndDropService: DragonDropService,
    private catalogueService: CatalogueService,
  ) {}

  public createModal(
    title: TemplateRef<{}> | string,
    content: TemplateRef<{}>,
    footer: TemplateRef<{}> | null,
    width: number = 520,
    className: string = '',
    style: { [key: string]: string } = null,
    height: string = 'auto',
    padding = '24px 24px 0 24px',
  ): void {
    const modalRef = this.modal.create({
      nzTitle: title,
      nzContent: content,
      nzFooter: footer,
      nzStyle: { textAlign: 'center', ...style },
      nzWidth: width,
      nzBodyStyle: { padding, height },
      nzClosable: true,
      nzMaskClosable: false,
      nzClassName: className,
      nzAutofocus: null,
      nzOnCancel: () => this.closeModal(),
      nzData: { columnType: className },
      nzAfterClose: this.modalClosed,
    });
    this.modalRefs.push(modalRef);

    const dragRef = this.dragDrop.createDrag(
      this.modalRefs[0]?.containerInstance?.modalElementRef.nativeElement,
    );

    this.dragAndDropService.isDisableDrag.subscribe((disable) => {
      dragRef.disabled = disable;
      if (!disable) {
        this.dispatchMouseUpEvent(this.modalRefs[0]?.containerInstance.modalElementRef);
      }
    });
  }

  public clearIds(): void {
    this.currentCampaignId = null;
    this.currentPromotionId = null;
    this.previousCampaignId = null;
    this.previousPromotionId = null;
  }

  public closeModal(): void {
    if (this.modalRefs?.length) {
      this.modalRefs.pop().close();
      if (this.modalRefs.length === 0) {
        this.catalogueService.isEditFromCatalogue = false;
      }
    }
  }

  public getBuildingBlockNames(type: keyof WorkSpaceDataType): string {
    const promotionId = this.currentPromotionId;
    const campaignId = this.currentCampaignId;

    if (campaignId && promotionId) {
      const campaign = workSpaceContent.find((el) => el.id === campaignId);
      const promotion = campaign.promotions.find((el: WorkSpaceContent) => el.id === promotionId);
      this.buildingBlockNames = promotion[type].map((el: Action) => el.title);
      return 'Promotion';
    }
    if (campaignId && !promotionId) {
      const campaign = workSpaceContent.find((el) => el.id === campaignId);
      this.buildingBlockNames = (campaign[type] as Action[]).map((el: Action) => el.title);
      return 'Campaign';
    }
    const promotion = workSpaceContent.find((el) => el.id === promotionId);
    this.buildingBlockNames = (promotion[type] as Action[]).map((el: Action) => el.title);
    return 'Promotion';
  }

  private dispatchMouseUpEvent(element: ElementRef) {
    element?.nativeElement?.dispatchEvent(this.mouseUpEvent);
  }
}
