import { Pipe, PipeTransform } from '@angular/core';
import { format } from "date-fns";

@Pipe({
  name: 'changeDateFormat'
})
export class ChangeDateFormatPipe implements PipeTransform {

  transform(value: string, dateFormat: string = 'dd.MM.yyyy'): string {
    if (value) {
      return format(new Date(value.split('.').join('/')), dateFormat);
    }
    return '';
  }

}
